<template>
	<div class="section-16">
		<div class="container-section">
			<img id="rectSect16" class="rect-absolute" src="/build/images/rect-sect-16.svg">

			<div class="content-section">
				<div class="header">
					<div class="content-left">
						<div class="title">Articole de suport detaliate pentru tine și colegii tăi</div>
						<button @click="openPricing" class="btn-view-tutorials">Gratuite cu abonamentul TBF <icon-arrow class="icon-arrow" /></button>
					</div>
					<div class="content-right">
						<div class="main-tutorial">
							<div class="data">
								<div class="category">Implementare</div>
								<div class="title">Ghid complet de implementare TBF Digital</div>
							</div>
							<div class="image-tutorial">
								<img src="/build/images/tutorial-test-2.png">
							</div>
						</div>
					</div>
				</div>
				<div class="slider-tutorials">
					<swiper class="swiper" :options="swiperOption">
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Ce sunt obiectivele și rezultatele cheie</div>
									<div class="category">Management</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Întâlnirea zilnică, săptămânală și trimestrială</div>
									<div class="category">Management</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Structura de organizare a companiei</div>
									<div class="category">Management</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Cum definești viziunea companiei</div>
									<div class="category">Management</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Întrebări pentru stabilirea unui obiectiv bun</div>
									<div class="category">Management</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Cunoaște interfața TBF Digital</div>
									<div class="category">Software</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Cum adaugi obiective și rezultate cheie</div>
									<div class="category">Software</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Cum adaugi și cum evaluezi promisiunea zilnică</div>
									<div class="category">Software</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Cum îți adaugi colegii în TBF Digital</div>
									<div class="category">Software</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Cum folosești dashboardul pentru claritate</div>
									<div class="category">Software</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Cum lucrezi cu grupurile de obiective</div>
									<div class="category">Software</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="tutorial-slide">
								<div class="data">
									<div class="title">Cum definești o strategie trimestrială</div>
									<div class="category">Software</div>
								</div>
								<div class="image-tutorial">
									<img src="/build/images/tutorial-test.png">
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import IconArrow from '../../assets/landing/Arrow'
	import { gsap } from "gsap";

	export default {
		data() {
			return {
				swiperOption: {
					slidesPerView: 'auto',
					centeredSlides: this.$mq.below(801) ? true : false,
					roundLengths: true,
					spaceBetween: 40,
					nested: true,
					loop: true
				}
			}
		},
		components: {
			IconArrow
		},
		mounted(){
			gsap.from("#rectSect16", {
				scrollTrigger: {
					trigger: '#rectSect16',
					start: "top center",
					end: "bottom center"
				},
				opacity: 0
			})
		},
		methods: {
			openPricing(){
				// window.open(this.$router.resolve({name: 'pricing'}).href, '_blank')
				this.$router.push({name: 'pricing'})
			}
		}
	}
</script>